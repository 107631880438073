import formatNumber from './formatNumber';

export const getYTickMaxWidth = (chartData) => {
  const maxValue = Math.max(
    ...chartData.data
      .flatMap((data) =>
        Object.entries(data)
          .filter(([key]) => key !== 'drillDownDataKey')
          .map(([, value]) => value),
      )
      .filter((value) => typeof value === 'number'),
  );

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = '16px Arial';

  const text = `${formatNumber(maxValue)} xx`;
  return context.measureText(text).width;
};
