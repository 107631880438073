import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';

import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const PersonStartDateModal = ({ assessmentData, isOpen, handleClick }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [useWithoutDate, setUseWithoutDate] = useState(false);

  useEffect(() => {
    if (assessmentData?.assessmentDate) {
      setSelectedDate(new Date(assessmentData?.assessmentDateOverride ?? assessmentData.assessmentDate));
    }
  }, [assessmentData]);

  const toggleUseDate = () => {
    setUseWithoutDate((prev) => !prev);
    if (!useWithoutDate) {
      setSelectedDate(null);
    }
  };

  const handleSetDate = (date) => {
    setUseWithoutDate(false);
    setSelectedDate(date);
  };

  return (
    <UIModalWrapper
      height="h-fit"
      additionalPadding="p-0"
      variant="center"
      isOpen={isOpen}
      onClose={() => handleClick('closeModal')}
    >
      <div className="flex flex-col justify-center mx-12 allModal">
        <div className="min-w-[560px] pt-7 pb-8 modal-title">
          <h3 className="text-left text-2xl font-bold title-body my-2">
            {t('ChooseAssessmentsPage.startDateModal.titleText')}
          </h3>
        </div>
        <p className="pb-6 text-sm max-w-[458px] font-semibold text-left modal-info">
          {`${t('ChooseAssessmentsPage.startDateModal.descriptionTextPart1')} 
            ${
              assessmentData?.assessmentDateOverride
                ? assessmentData?.assessmentDateOverride.slice(0, 10)
                : assessmentData?.assessmentDate?.slice(0, 10)
            }?
          `}
        </p>
        <Calendar
          date={selectedDate}
          onChange={(date) => handleSetDate(date)}
          locale={sv}
          color="hsl(193, 19%, 38%)"
          showMonthArrow={false}
          className={`${useWithoutDate ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''} self-center`}
        />
      </div>
      <div className="self-center w-72">
        <UIButtonCheckbox
          description={t('AssessmentHistoryPage.dateModal.clearDateText')}
          checked={useWithoutDate}
          setChecked={toggleUseDate}
          styleDesc="text-lg font-normal"
        />
      </div>
      <div className="w-full background-secondary md:mt-[40px] mt-[70px] py-5 model-margin">
        <div className="flex justify-center">
          <button
            onClick={() => handleClick('closeModal')}
            className="border button-outlined py-3 px-[60px] mr-5 font-semibold text-sm
                    title rounded-lg"
          >
            {t('ChooseAssessmentsPage.startDateModal.cancelText')}
          </button>
          <UIButtonSubmit
            name={t('ChooseAssessmentsPage.startDateModal.submitText')}
            isIcon={false}
            assessments={true}
            enabled={useWithoutDate || selectedDate != null}
            onSubmit={() => handleClick('setStartDate', assessmentData?.assessmentId, selectedDate)}
          />
        </div>
      </div>
    </UIModalWrapper>
  );
};

PersonStartDateModal.propTypes = {
  assessmentData: PropTypes.object,
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default PersonStartDateModal;
