import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { HiOutlineArrowUturnRight } from 'react-icons/hi2';
import { HiOutlineClock, HiOutlineDocumentAdd } from 'react-icons/hi';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

import formatDate from 'utils/global/formatDate';

import { currentUser, currentUserRegion } from 'state/global/currentUser';
import { chosenTemplate } from 'state/global/templates';
// eslint-disable-next-line max-len
import { assessmentHistory as assessmentHistoryRecoil } from 'state/KubenAssessments/assessmentHistory/assessmentHistory';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { chosenAssessmentAction } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { chosenRegion } from 'state/KubenAssessments/choosingTemplate/region';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { regionOptions } from 'state/global/region';

import usePersonDetails from 'hooks/api/person/usePersonDetails';
import useFetchIncompleteAssessment from 'hooks/api/assessmentResult/useFetchIncompleteAssessment';
import useFetchPersonAssessmentHistory from 'hooks/api/assessmentResult/useFetchPersonAssessmentHistory';
import useFetchRegionList from 'hooks/api/region/useFetchRegionList';
import useFetchGraphPersonData from 'hooks/api/graph/useFetchGraphPersonData';
import useFetchGraphDefinitions from 'hooks/api/graph/useFetchGraphDefinitions';
import actions from 'api/lifeCareIntegration';

import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import PersonDataCard from 'components/KubenAssessments/AssessmentPersonPage/PersonDataCard';
import ChooseAssessmentChoiceCard from 'components/KubenAssessments/ChooseAssessmentPage/ChooseAssessmentChoiceCard';
import PersonDashboard from 'components/KubenAssessments/AssessmentPersonPage/PersonDashboard';
import PersonStartDateModal from 'components/KubenAssessments/AssessmentPersonPage/PersonStartDateModal';
import PersonListModal from 'components/KubenAssessments/AssessmentPersonPage/PersonListModal';

const AssessmentsPersonPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { personId } = useParams();
  const activeRegion = useRecoilValue(currentUserRegion);
  const setRegionData = useSetRecoilState(regionOptions);

  const startDate = format(startOfMonth(subMonths(new Date(), 12)), 'yyyy-MM-dd');
  const endDate = format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd');

  const selectedPerson = useRecoilValue(selectedPersonRecoil);
  const user = useRecoilValue(currentUser);
  const setChosenAssessment = useSetRecoilState(chosenAssessmentAction);
  const setChosenTemplate = useSetRecoilState(chosenTemplate);
  const setChosenRegion = useSetRecoilState(chosenRegion);
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const assessmentHistory = useRecoilValue(assessmentHistoryRecoil);

  const [isShowLifeCareDataList, setShowLifeCareDataList] = useState(false);
  const [isShowStartDateModal, setShowStartDateModal] = useState(false);

  const {
    isLoading: isExistingPersonLoading,
    isError: isExistingPersonError,
    refetch: refetchExistingPerson,
  } = usePersonDetails(personId);

  const {
    isFetching: isGraphDataLoading,
    isError: isGraphDataError,
    data: graphData,
  } = useFetchGraphPersonData(personId, startDate, endDate);

  const {
    isLoading: isGraphDefinitionsLoading,
    isError: isGraphDefinitionsError,
    data: graphDefinitions,
  } = useFetchGraphDefinitions();

  const { isLoading: isRegionsLoading, isError: isRegionsError, data: regionsData } = useFetchRegionList();

  const {
    isLoading: isIncompleteAssessmentLoading,
    isError: isIncompleteAssessmentError,
    data: incompleteAssessmentData,
  } = useFetchIncompleteAssessment(personId);

  const {
    isLoading: isAssessmentHistoryLoading,
    isError: isAssessmentHistoryError,
    mutate: mutateAssessmentHistoryData,
  } = useFetchPersonAssessmentHistory(personId, 'person', 9, 1);

  const {
    data: lifeCareData,
    isLoading: isLifeCareDataLoading,
    isError: isFetchLifeCareDataForPersonError,
    refetch: refetchLifeCareData,
  } = useQuery({
    queryKey: ['lifeCareDataForPerson'],
    queryFn: () => actions.fetchLifeCareDataForPerson(activeRegion.id, personId),
    enabled: isShowLifeCareDataList,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

  const {
    mutate: mutateSetAssessmentOverrideForPerson,
    isLoading: isSetAssessmentOverrideForPersonLoading,
    isError: isSetAssessmentOverrideForPersonError,
  } = useMutation({
    mutationFn: async (data) => {
      const response = await actions.setAssessmentOverrideForPerson(data);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['lifeCareDataForPerson']);
    },
  });

  const filteredLifeCareData = lifeCareData?.ibicAssessments?.map((el) => ({
    ...el,
  }));

  const [ibicAssessment, setIbicAssessment] = useState(filteredLifeCareData);

  const onAssessmentCardClick = (action, url) => {
    if (action === 'newAssessment') {
      setChosenAssessment(action);
      history.push(url);
    } else if (action === 'existingAssessment') {
      setChosenAssessment(action);
      setChosenRegion(incompleteAssessmentData?.data?.region);
      setChosenTemplate(incompleteAssessmentData?.data?.assessment);
      setAnswersAssessment(incompleteAssessmentData?.data);
      history.push(url);
    } else if (action === 'assessmentHistory') {
      history.push(url);
    }
  };

  const handleLifeCareDataListClick = (el) => {
    setIbicAssessment(el);
    setShowStartDateModal(true);
  };

  const handleStartDateModalClick = (action, id, assessmentStartDate) => {
    if (action === 'closeModal') {
      setShowStartDateModal(false);
    }
    if (action === 'setStartDate') {
      const updatedAssessment = {
        personId: personId,
        regionId: activeRegion.id,
        assessmentId: id,
        assessmentDateOverride: assessmentStartDate ? formatDate(assessmentStartDate, 'yyyy-MM-dd') : null,
      };

      mutateSetAssessmentOverrideForPerson(updatedAssessment, {
        onSuccess: () => {
          refetchLifeCareData();
        },
      });
    }

    setShowStartDateModal(false);
  };

  useEffect(() => {
    if (personId) {
      mutateAssessmentHistoryData();
      refetchExistingPerson();
    }
  }, [personId]);

  useEffect(() => {
    if (regionsData) {
      setRegionData(regionsData);
    }
  }, [regionsData]);

  const isDashboardLoading = isGraphDefinitionsLoading || isGraphDataLoading;
  const isAssessmentsLoading = isIncompleteAssessmentLoading || isAssessmentHistoryLoading;
  const isPersonLoading =
    isExistingPersonLoading ||
    isRegionsLoading ||
    !selectedPerson?.id ||
    isGraphDataLoading ||
    isSetAssessmentOverrideForPersonLoading;

  if (
    isGraphDefinitionsError ||
    isGraphDataError ||
    isIncompleteAssessmentError ||
    isAssessmentHistoryError ||
    isExistingPersonError ||
    isRegionsError ||
    isSetAssessmentOverrideForPersonError
  ) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <div className="flex max-w-[1640px] mx-auto assesment-wrapper h-full space-x-4">
        <PersonDataCard
          id={selectedPerson?.id}
          fullName={selectedPerson?.name}
          customerNumber={selectedPerson?.customerNo}
          socialSecurityNumber={selectedPerson?.socialSecurityNumber}
          gender={selectedPerson?.gender}
          reportId={selectedPerson?.reportId}
          birthYear={selectedPerson?.yearOfBirth}
          tags={graphData?.tags}
          region={activeRegion}
          isLoading={isPersonLoading}
          personId={personId}
          setShowLifeCareDataList={setShowLifeCareDataList}
        />
        <div className="flex flex-col space-y-4 h-full">
          <div className="flex space-x-4">
            <ChooseAssessmentChoiceCard
              url="/assessments/choose-template?person=true"
              icon={<HiOutlineDocumentAdd />}
              title={t('ChooseAssessmentsPage.createNewAssessmentTitle')}
              description={t('ChooseAssessmentsPage.createNewAssessmentText')}
              action="newAssessment"
              handleClick={onAssessmentCardClick}
              isDisabled={isAssessmentsLoading}
            />
            <ChooseAssessmentChoiceCard
              url="/assessments/assessment-history/person"
              icon={<HiOutlineClock />}
              title={t('ChooseAssessmentsPage.previousAssessmentsTitle')}
              description={t('ChooseAssessmentsPage.previousAssessmentsText')}
              isDisabled={assessmentHistory?.results?.length === 0 || isAssessmentsLoading}
              action="assessmentHistory"
              handleClick={onAssessmentCardClick}
            />
            <ChooseAssessmentChoiceCard
              url="/assessments/questions"
              icon={<HiOutlineArrowUturnRight />}
              title={t('ChooseAssessmentsPage.continuePreviousAssessmentTitle')}
              description={t('ChooseAssessmentsPage.continuePreviousAssessmentText')}
              isDisabled={incompleteAssessmentData?.data == null || isAssessmentsLoading}
              action="existingAssessment"
              handleClick={onAssessmentCardClick}
            />
          </div>
          <PersonDashboard
            graphDefinitions={graphDefinitions}
            graphData={graphData}
            hasAccess={user?.hasAccessToDashboard}
            isLoading={isDashboardLoading}
          />
        </div>
        <PersonListModal
          isOpen={isShowLifeCareDataList}
          onClose={() => setShowLifeCareDataList(false)}
          modalTitle="IBIC Bedömningar"
          data={filteredLifeCareData}
          handleClick={(el) => handleLifeCareDataListClick(el)}
          isError={isFetchLifeCareDataForPersonError}
          isLoading={isLifeCareDataLoading}
        />
        <PersonStartDateModal
          isOpen={isShowStartDateModal}
          assessmentData={ibicAssessment}
          handleClick={(action, id, date) => handleStartDateModalClick(action, id, date)}
        />
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default AssessmentsPersonPage;
