import query from '../useQueryWrapper';
import actions from 'api/businessType';

const useFetchOperatingCostTypes = () => {
  return query.useQueryWrapper(
    'operatingCostTypes',
    async () => {
      const operatingCostTypes = await actions.fetchOperatingCostTypes();
      return operatingCostTypes;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchOperatingCostTypes;
