import React from 'react';
import PropTypes from 'prop-types';

const PersonLifeCareDataCard = ({ data }) => {
  return (
    <div className="rounded-lg flex-col border bg-white">
      {data.map((el) => (
        <div
          key={el.decisionId}
          className="text-sm font-semibold text-left p-4"
        >
          <p>{`Besluts ID: ${el.decisionId}`}</p>
          <p>{`Beslutskod: ${el.decisionCode}`}</p>
          <p>{`Beslutstyp: ${el.decisionType}`}</p>
          <p>{`Från: ${el.fromDate}`}</p>
          <p>{el.toDate && `Till: ${el?.toDate}`}</p>
        </div>
      ))}
    </div>
  );
};

PersonLifeCareDataCard.propTypes = {
  data: PropTypes.array,
};

export default PersonLifeCareDataCard;
