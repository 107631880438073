import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import jobActions from 'api/job';
import { jobStatus as jobStatusRecoil } from 'state/global/jobStatus';

const useFetchJobStatus = (id) => {
  const setJobStatus = useSetRecoilState(jobStatusRecoil);

  return query.useQueryWrapper(
    'reportStatus',
    async () => {
      const jobStatus = await jobActions.fetchReportStatus(id);
      setJobStatus(jobStatus?.data);
      return jobStatus;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchJobStatus;
