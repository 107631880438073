import React, { useRef, useState, Children } from 'react';
import PropTypes from 'prop-types';
import { BsThreeDotsVertical } from 'react-icons/bs';
import closeElementFunction from 'utils/global/closeElementFunction';

const PersonAssessmentPageDropdown = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const childrenArray = Children.toArray(children);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setIsVisible);

  return (
    <div className="my-auto relative icon">
      <BsThreeDotsVertical
        onClick={() => setIsVisible(!isVisible)}
        className="cursor-pointer text-sm"
      />
      {isVisible && (
        <div
          ref={wrapperRef}
          className="absolute right-0 z-10 mt-2 w-30 origin-top-right rounded-md background
            shadow-lg ring-1 ring-[black] ring-opacity-5 focus:outline-none text-left"
        >
          {childrenArray.map((child, index) => (
            <div
              key={index}
              className="h-8 text-xs options-dropdown-children cursor-pointer"
            >
              {child}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

PersonAssessmentPageDropdown.propTypes = {
  children: PropTypes.node,
  iconSize: PropTypes.string,
};

export default PersonAssessmentPageDropdown;
