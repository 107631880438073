import query from '../useQueryWrapper';
import actions from 'api/businessType';

const useFetchOperatingCostDistributionKeys = () => {
  return query.useQueryWrapper(
    'operatingCostDistributionKeys',
    async () => {
      const operatingCostDistributionKeys = await actions.fetchOperatingCostDistributionKeys();
      return operatingCostDistributionKeys;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchOperatingCostDistributionKeys;
