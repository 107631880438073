import React from 'react';
import PropTypes from 'prop-types';
import { IoLockClosedOutline } from 'react-icons/io5';

import PersonTrend from './PersonTrend';
import PersonChartWrapper from './PersonChartWrapper';
import PersonGridCard from './PersonGridCard';
import PersonUnitsCard from './PersonUnitsCard';
import StartIcon from 'components/icons/KubenDashboard/StartIcon';
import UITag from 'components/global/UICommon/UITag';
import UISpinner from 'components/global/UICommon/UISpinner';

const PersonDashboard = ({ graphDefinitions, hasAccess, graphData, isLoading }) => {
  return (
    <div
      className="w-full flex-grow bg-white border border-tertiary rounded-2xl py-10 px-14 relative
     overflow-y-auto"
    >
      <div className="flex justify-between">
        <div className="flex flex-col text-left space-y-1">
          <div className="flex items-center space-x-2">
            <h2 className="text-2xl font-medium text-primary">Dashboard</h2>
            {!hasAccess && <IoLockClosedOutline className="text-xl" />}
          </div>
          <h4 className="text-sm text-muted">Visar data för de senaste 12 månaderna</h4>
        </div>
        {!isLoading && <div>{graphData?.analyticsTrend && <PersonTrend trendType={graphData.analyticsTrend} />}</div>}
      </div>
      {isLoading ? (
        <div>
          <UISpinner theme="fast" />
        </div>
      ) : (
        <div className="person-graph-grid pt-8">
          {graphData?.staticGraphs.map((graphData, idx) => (
            <PersonChartWrapper
              key={idx}
              hasAccess={hasAccess}
              chartId={graphData.id}
              graphDefinition={graphDefinitions.find((g) => g.graphId == graphData.id)}
              data={graphData}
            />
          ))}
          {graphData?.analyticsCategories.length > 0 && (
            <PersonGridCard
              title="Kategorier"
              icon={<StartIcon />}
              className="row-span-4"
              isDataBlurred={!hasAccess}
            >
              {graphData.analyticsCategories.map((tag, idx) => (
                <UITag
                  key={idx}
                  id={idx}
                  name={tag}
                />
              ))}
            </PersonGridCard>
          )}
          {graphData?.unitLevels.length > 0 && <PersonUnitsCard unitLevels={graphData.unitLevels} />}
        </div>
      )}
    </div>
  );
};

PersonDashboard.propTypes = {
  graphDefinitions: PropTypes.array,
  graphData: PropTypes.object,
  isLoading: PropTypes.bool,
  hasAccess: PropTypes.bool,
};

export default PersonDashboard;
