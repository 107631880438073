import client from './clients/base';

const URL = 'lifeCareIntegration/';

const fetchLifeCareDataForPerson = async (regionId, personId) => {
  const response = await client.get(`${URL}get-lifecare-data-for-person/${regionId}/${personId}`);
  return response.data;
};

const setAssessmentOverrideForPerson = async (body) => {
  const response = await client.post(`${URL}set-assessment-override-for-person`, body);
  return response.data;
};

const actions = {
  fetchLifeCareDataForPerson,
  setAssessmentOverrideForPerson,
};

export default actions;
