import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import formatDate from 'utils/KubenAssessments/formatDate';
import useFetchCompletedAssessment from 'hooks/api/assessmentResult/useFetchCompletedAssessment';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIBackButton from 'components/global/UIButtons/UIBackButton';
import UIAssessmentTable from 'components/KubenAssessments/UIAssessments/UIAssessmentTable';

const AssessmentsServicePage = () => {
  const { assessmentId } = useParams();

  const { isLoading, data, mutate, isError } = useFetchCompletedAssessment();

  useEffect(() => {
    if (assessmentId) {
      mutate({ id: assessmentId });
    }
  }, [assessmentId]);

  return (
    <UIStaticComponentsWrapper>
      <div
        className="flex max-w-[1640px] mx-auto h-full
      h-full w-full mx-auto assessmentPage text-left my-auto"
      >
        <div className="flex flex-col w-full h-full space-y-6">
          <div className="w-fit">
            <UIBackButton />
          </div>
          <div className="h-full w-full flex space-x-4 pb-6">
            <UIAssessmentTable
              title="Insatser"
              isLoading={isLoading}
              isError={isError}
              titles={['namn', 'enhet', 'fråga', 'startdatum', 'stoppdatum']}
              data={data?.data.orderedServices.map((row) => [
                row.service?.name,
                row.unit?.name,
                row.question?.name,
                row.startDate ? formatDate(row.startDate) : null,
                row.endDate ? formatDate(row.endDate) : null,
              ])}
            />
          </div>
        </div>
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default AssessmentsServicePage;
