import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import formatNumber from 'utils/KubenDashboard/formatNumber';
import { getYTickMaxWidth } from 'utils/KubenDashboard/getYTickMaxWidth';
import DashboardPreviewChartToolTip from 'components/KubenDashboard/Charts/DashboardPreviewChartToolTip';

const PersonChart = ({ chartData, isExpanded, hasAccess = false }) => {
  const formatXAxisTick = (value) => value.substring(0, 3);
  const units = chartData.titles?.length > 0 ? chartData.titles[0].units : '';
  const formatYAxisTick = useCallback((value) => `${formatNumber(value)}\u00A0${units}`, [chartData]);
  const yTickWidth = getYTickMaxWidth(chartData);

  const renderGradientDefs = () => (
    <defs>
      {chartData.titles.map((title, idx) => (
        <linearGradient
          key={idx}
          id={`${title.id}colorUv`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop
            stopColor={title.color}
            stopOpacity="40%"
          />
          <stop
            offset="95%"
            stopColor={title.color}
            stopOpacity="1%"
          />
        </linearGradient>
      ))}
    </defs>
  );

  return (
    <ResponsiveContainer
      className="absolute top-0 right-0"
      width="100%"
      height="100%"
      animationDuration={0}
      debounce={0}
    >
      <AreaChart
        data={chartData.data}
        margin={{ top: isExpanded ? 100 : 5, right: 5, left: 0, bottom: 10 }}
      >
        {hasAccess && <Tooltip content={<DashboardPreviewChartToolTip />} />}
        {renderGradientDefs()}
        {isExpanded && (
          <>
            <XAxis
              dataKey="name"
              tickMargin={10}
              tick={{ fill: 'hsl(0, 0%, 60%)' }}
              axisLine={false}
              tickLine={false}
              tickFormatter={formatXAxisTick}
            />
            <YAxis
              tickFormatter={(value) => formatYAxisTick(value)}
              animationDuration={0}
              width={yTickWidth}
              axisLine={false}
              tickLine={false}
              style={{ fontSize: '14px' }}
              tick={{ fill: 'hsl(0, 0%, 60%)' }}
            />
          </>
        )}
        {chartData.titles.map((title, idx) => (
          <Area
            key={idx}
            unit={title.units}
            type={chartData.options.chartType}
            dataKey={title.id}
            stroke={title.color}
            strokeWidth={3}
            animationDuration={0}
            fill={`url(#${title.id}colorUv)`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

PersonChart.propTypes = {
  chartData: PropTypes.shape({
    data: PropTypes.array.isRequired,
    titles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        units: PropTypes.string.isRequired,
      }),
    ).isRequired,
    options: PropTypes.shape({
      chartType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  hasAccess: PropTypes.bool.isRequired,
};

export default PersonChart;
