import client from './clients/base';

const URL = 'businessType/';

const fetchBusinessTypes = async () => {
  const response = await client.get(`${URL}load-all/`);
  return response.data;
};

const fetchBusinessTypesForRegion = async (id) => {
  const response = await client.get(`${URL}load-all-for-region/${id}`);
  return response.data;
};

const fetchBusinessType = async (id) => {
  const response = await client.get(`${URL}load/${id}`);
  return response.data;
};

const fetchBusinessTypeForRegion = async (id, regionId) => {
  const response = await client.get(`${URL}load/${id}/${regionId}`);
  return response.data;
};

const fetchIcfCodes = async () => {
  const response = await client.get(`${URL}icf-codes/`);
  return response.data;
};

const fetchIcfCode = async (id) => {
  const response = await client.get(`${URL}icf-code/${id}`);
  return response.data;
};

const fetchNightStaffings = async () => {
  const response = await client.get(`${URL}night-staffings/`);
  return response.data;
};

const fetchUnitTypes = async () => {
  const response = await client.get(`${URL}unit-types/`);
  return response.data;
};

const editIcfCode = async (body) => {
  const response = await client.post(`${URL}icf-code`, body);
  return response.data;
};

const deleteIcfCode = async (id) => {
  const response = await client.delete(`${URL}icf-code/${id}`);
  return response.data;
};

const saveBusinessType = async (body) => {
  const response = await client.post(`${URL}save/`, body);
  return response.data;
};

const fetchResourceGroups = async () => {
  const response = await client.get(`${URL}resource-groups/`);
  return response.data;
};

const fetchOperatingCostTypes = async () => {
  const response = await client.get(`${URL}operating-cost-types`);
  return response.data;
};

const fetchOperatingCostDistributionKeys = async () => {
  const response = await client.get(`${URL}operating-cost-distribution-keys`);
  return response.data;
};

const actions = {
  fetchBusinessTypes,
  fetchBusinessTypesForRegion,
  fetchBusinessType,
  fetchBusinessTypeForRegion,
  fetchIcfCodes,
  fetchIcfCode,
  fetchNightStaffings,
  fetchUnitTypes,
  editIcfCode,
  deleteIcfCode,
  saveBusinessType,
  fetchResourceGroups,
  fetchOperatingCostTypes,
  fetchOperatingCostDistributionKeys,
};

export default actions;
