import React from 'react';
import PropTypes from 'prop-types';

import UIModalWrapper from 'components/global/UIModals/UIModalWrapper';
import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';
import PersonLifeCareDataCard from 'components/KubenAssessments/AssessmentPersonPage/PersonLifeCareDataCard';
import UISpinner from 'components/global/UICommon/UISpinner';

const PersonListModal = ({ modalTitle, isOpen, onClose, data, handleClick, isError, isLoading }) => {
  return (
    <UIModalWrapper
      height="h-fit"
      additionalPadding="p-0 "
      variant="center"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div className="w-940px h-80vh list-modal pt-11 overflow-y-hidden relative">
        <div className="flex justify-between pb-6 w-full">
          <h2 className="text-2xl font-bold title pl-11">{modalTitle}</h2>
        </div>
        <div className="flex-col pt-3 border-t border-tertiary overflow-auto">
          {isError ? (
            <UIErrorMessage />
          ) : isLoading ? (
            <UISpinner theme="fast" />
          ) : (
            <table className="w-full table-fixed">
              <thead>
                <tr className="text-sm subtitle font-semibold text-left">
                  <th className="pl-11">Bedömnings ID</th>
                  <th className="pl-11">Utrednings ID</th>
                  <th className="pl-11">Bedömningsdatum</th>
                  <th className="pl-11">Ändrat startdatum</th>
                </tr>
              </thead>
              <tbody className="overflow-auto">
                {data?.map((el, idx) => (
                  <tr
                    key={idx}
                    onClick={() => {
                      handleClick(el);
                      onClose();
                    }}
                    className="list-modal-element cursor-pointer text-sm font-semibold title text-left group"
                  >
                    <td className="pl-11 py-4">{el.assessmentId}</td>
                    <td className="pl-11 py-4">{el.investigationId}</td>
                    <td className="pl-11 py-4">{el.assessmentDate}</td>
                    <td className="pl-11 py-4">{el?.assessmentDateOverride}</td>
                    <td
                      className="transition-opacity absolute right-0
                       translate-x-[-35%] translate-y-[20%] opacity-0 group-hover:opacity-100"
                    >
                      <PersonLifeCareDataCard data={el.decisions} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </UIModalWrapper>
  );
};

PersonListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  data: PropTypes.array,
  modalTitle: PropTypes.string,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default PersonListModal;
