import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIButtonLink from 'components/KubenAnalytics/UIAnalytics/UIButtonLink';

const UIAssessmentTable = ({ title, titles, data, isError, isLoading, url }) => {
  const { t } = useTranslation();

  return (
    <div
      className="rounded-xl h-full w-full border border-tertiary overflow-hidden bg-white relative
     overflow-y-auto"
    >
      {isError ? (
        <UIErrorMessage />
      ) : (
        <>
          <div className="text-left text-xl p-8 flex justify-between items-center">
            <p className="title text-2xl">{title}</p>
            {url && !isLoading && data?.length > 0 && (
              <UIButtonLink
                link={url}
                name={t('AssessmentDashboardPage.links.seeAll')}
              />
            )}
          </div>
          {isLoading ? (
            <UILoadingModal />
          ) : (
            <table
              className="w-full text-primary table-fixed border-separate border-spacing-x-8 border-spacing-y-3
          overflow-y-auto relative"
            >
              <thead className="uppercase text-base">
                <tr>
                  {titles.map((t, idx) => (
                    <td
                      key={idx}
                      className="last:text-right"
                    >
                      {t}
                    </td>
                  ))}
                </tr>
              </thead>
              <div className="w-full h-0.5 background-tertiary absolute left-0 top-12" />
              <tbody>
                {data?.length > 0 ? (
                  data.map((row, idx) => (
                    <tr
                      key={idx}
                      className="h-10"
                    >
                      {row?.map((col, idx) => (
                        <td
                          key={idx}
                          className="first:relative last:text-right"
                        >
                          {col || '-'}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <div className="h-32 w-full flex justify-center items-center absolute left-0">
                    <span className="text-xl">Ingen data</span>
                  </div>
                )}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

UIAssessmentTable.propTypes = {
  title: PropTypes.string.isRequired,
  titles: PropTypes.array,
  data: PropTypes.array,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  url: PropTypes.string,
};

export default UIAssessmentTable;
