import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getFirstLetters } from 'utils/KubenAssessments/trimUserName';
import { formatSocialSecurityNumber } from 'utils/global/formatSocialSecurityNumber';
import PersonDataCardBanner from './PersonDataCardBanner';
import PersonDataRowToggleValue from './PersonDataRowToggleValue';
import PersonDataRow from './PersonDataRow';
import PersonTags from './PersonTags';
import PersonFetchLifeCareDataButton from './PersonFetchLifeCareDataButton';

const PersonDataCard = ({
  id,
  tags,
  fullName,
  gender = 'male',
  socialSecurityNumber,
  reportId,
  personId,
  customerNumber,
  birthYear,
  region,
  isLoading,
  setShowLifeCareDataList,
}) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('UINameHolder.gender'),
      value: t(`UINameHolder.${gender.toLowerCase()}`),
    },
    {
      label: t('UINameHolder.age'),
      value: `${new Date().getFullYear() - birthYear} år`,
    },
    {
      label: t('UINameHolder.reportId'),
      value: reportId,
      isCopy: true,
    },
    {
      label: t('UINameHolder.customerNumber'),
      value: customerNumber,
    },
  ];

  return (
    <div className="flex flex-col background border border-tertiary p-2 rounded-xl overflow-hidden w-2/6">
      <div className="w-full flex flex-col relative mb-8">
        <PersonDataCardBanner />
        <PersonFetchLifeCareDataButton
          personId={personId}
          region={region}
          setShowLifeCareDataList={setShowLifeCareDataList}
        />
        {!isLoading && (
          <div className="flex flex-col items-center space-y-2 mt-12">
            <div
              className="background-primary rounded-full min-w-[120px] w-1/3 aspect-square background-highlight 
            relative"
            >
              <span
                className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2
            text-5xl 3xl:text-6xl font-medium pt-2 text-accent"
              >
                {getFirstLetters(fullName)}
              </span>
            </div>
            <PersonDataRowToggleValue
              value={fullName.replace(/[a-ö]/g, '*')}
              toggleValue={fullName}
              className="font-semibold text-xl whitespace-nowrap"
            />
            {socialSecurityNumber && (
              <PersonDataRowToggleValue
                value={formatSocialSecurityNumber(socialSecurityNumber).replace(/.{4}$/, 'XXXX')}
                toggleValue={formatSocialSecurityNumber(socialSecurityNumber)}
                className="font-medium text-md whitespace-nowrap"
              />
            )}
          </div>
        )}
      </div>
      {!isLoading && (
        <div className="space-y-8 flex flex-col p-5">
          {region && (
            <PersonTags
              personId={id}
              tags={tags}
              region={region}
            />
          )}
          {items
            .filter((item) => item.value !== null)
            .map((item, idx) => (
              <PersonDataRow
                key={idx}
                label={item.label}
                value={item.value}
                isCopy={item.isCopy}
              />
            ))}
        </div>
      )}
    </div>
  );
};

PersonDataCard.propTypes = {
  tags: PropTypes.array,
  id: PropTypes.string,
  fullName: PropTypes.string,
  isButton: PropTypes.bool,
  isDashboardStyling: PropTypes.bool,
  gender: PropTypes.string,
  buttonFunction: PropTypes.func,
  model: PropTypes.string,
  socialSecurityNumber: PropTypes.string,
  reportId: PropTypes.string,
  personId: PropTypes.string,
  birthYear: PropTypes.number,
  customerNumber: PropTypes.string,
  region: PropTypes.object,
  isLoading: PropTypes.bool,
  setShowLifeCareDataList: PropTypes.func,
};

export default PersonDataCard;
