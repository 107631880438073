import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LuRefreshCcw } from 'react-icons/lu';
import { useRecoilValue } from 'recoil';

import { currentUser } from 'state/global/currentUser';
import actions from 'api/person';
import jobActions from 'api/job';

// eslint-disable-next-line max-len
import PersonAssessmentPageDropdown from 'components/KubenAssessments/AssessmentPersonPage/PersonAssessmentPageDropdown.js';

const PersonFetchLifeCareDataButton = ({ personId, region, setShowLifeCareDataList }) => {
  const queryClient = useQueryClient();
  const user = useRecoilValue(currentUser);

  const [jobStatusArgs, setJobStatusArgs] = useState({ isLoading: false, jobId: null, jobIntervalId: null });

  const { refetch } = useQuery({
    queryKey: ['jobStatus'],
    queryFn: () => jobActions.fetchReportStatus(jobStatusArgs.jobId),
    onSuccess: (data) => {
      const { status } = data.data;
      if (status === 'Finished' || status === 'Failed') {
        clearInterval(jobStatusArgs.jobIntervalId);
        setJobStatusArgs({
          isLoading: false,
          jobId: null,
          jobIntervalId: null,
        });
        queryClient.invalidateQueries(['useFetchGraphPersonData']);
      }
    },
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

  const { mutate: mutateLifeCare, isLoading: isLoading } = useMutation({
    mutationFn: () => actions.rerunLifeCareIntegration(region.id, personId),

    onSuccess: (data) => {
      if (data.id) {
        const jobIntervalId = setInterval(() => {
          refetch();

          if (jobIntervalId) {
            refetch();
          }
        }, 1_000);

        setJobStatusArgs({
          isLoading: true,
          jobId: data.id,
          jobIntervalId: jobIntervalId,
        });
      }
    },
  });

  const handleUpdate = () => {
    mutateLifeCare();
  };

  const isRunning = isLoading || jobStatusArgs.isLoading;

  const handleStartDateOptionClick = () => {
    setShowLifeCareDataList(true);
  };

  const options = [
    {
      label: (
        <div className="flex items-center">
          <LuRefreshCcw
            className={`${isRunning ? 'animate-spin transition-all' : ''} 
          w-3 h-3 cursor-pointer`}
          />
          <span className="flex justify ml-2 text-left">Uppdatera data</span>
        </div>
      ),
      onClick: handleUpdate,
      isVisible: true,
      isDisabled: isRunning,
    },
    {
      label: (
        <div className="flex items-center">
          <span className="flex justify ml-2 text-left">Ändra startdatum</span>
        </div>
      ),
      onClick: handleStartDateOptionClick,
      isVisible: user.defaultRegionHasEnabledIntegration,
    },
  ];

  return (
    <div className="flex justify-end">
      <div className="group relative w-6 h-6 mt-2 mr-2 bg-white rounded flex items-center justify-center">
        <PersonAssessmentPageDropdown>
          {options
            .filter((option) => option.isVisible)
            .map((option, index) => (
              <button
                key={index}
                className="w-max pl-3 pr-3 h-full flex text-primary"
                onClick={option.onClick}
                disabled={option.isDisabled}
              >
                {option.label}
              </button>
            ))}
        </PersonAssessmentPageDropdown>
      </div>
    </div>
  );
};

PersonFetchLifeCareDataButton.propTypes = {
  personId: PropTypes.string.isRequired,
  region: PropTypes.object,
  setShowLifeCareDataList: PropTypes.func,
};

export default PersonFetchLifeCareDataButton;
