import { React, useState } from 'react';
import propTypes from 'prop-types';
import getUUID from 'utils/KubenAdmin/getUUID';

import UIButtonDropdownGroup from 'components/global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from 'components/global/UIButtons/UIItemDropdown';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIWarningModal from 'components/global/UIModals/UIWarningModal';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import UIFoldableCard from '../UIAdmin/UIFoldableCard';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import RegionInput from 'components/KubenAdmin/RegionsPage/RegionInput';

import useFetchOperatingCostTypes from 'hooks/api/businessType/useFetchOperatingCostTypes';
import useFetchOperatingCostDistributionKeys from 'hooks/api/businessType/useFetchOperatingCostDistributionKeys';

const RegionOperatingCostsVersionsForm = ({
  setElement,
  operatingCostsVersions,
  element,
  onEditVersion,
  isReadonly,
  onOpenAddNewVersionModal,
  isAdd,
  onChangeVersion,
}) => {
  const [openWarningModal, setOpenWarningModal] = useState();

  const {
    data: operatingCostTypes,
    isLoading: isOperatingCostTypesLoading,
    isError: isOperatingCostTypesError,
  } = useFetchOperatingCostTypes();
  const {
    data: operatingCostDistributionKeys,
    isLoading: isOperatingCostDistributionKeysLoading,
    isError: isOperatingCostDistributionKeysError,
  } = useFetchOperatingCostDistributionKeys();

  const handleCreateModalClick = () => {
    const newOperatingCost = {
      id: getUUID(),
      name: 'Driftkostnad',
      cost: 100000,
      costType: {
        id: 'Rent',
        value: 'Lokalhyra',
      },
      distributionKey: {
        id: 'UserResources',
        value: 'Årsarbetare',
      },
    };

    setElement((prev) => {
      if (!prev) {
        return {
          id: getUUID(),
          startDate: null,
          endDate: null,
          operatingCosts: [newOperatingCost],
        };
      }

      return {
        ...prev,
        operatingCosts: [...prev.operatingCosts, newOperatingCost],
      };
    });

    setOpenWarningModal(false);
  };

  if (isOperatingCostTypesLoading || isOperatingCostDistributionKeysLoading) {
    return <UILoadingModal />;
  }

  if (isOperatingCostTypesError || isOperatingCostDistributionKeysError) {
    return (
      <UIErrorModal
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIFoldableCard
      title="Driftkostnader"
      defaultOpen={true}
    >
      {!isAdd && (
        <div className="flex flex-col justify-center items-end space-y-4">
          <div className="flex justify-end space-x-6">
            <UIButtonSubmit
              name="Redigera befintlig version"
              onSubmit={onEditVersion}
              assessments={true}
              isIcon={false}
              fixedWidth={false}
            />
            <UIButtonSubmit
              name="Skapa ny version"
              onSubmit={onOpenAddNewVersionModal}
              assessments={true}
              isIcon={false}
              fixedWidth={false}
              enabled={operatingCostsVersions.length > 0}
            />
          </div>
          {operatingCostsVersions.length > 1 && (
            <div className="flex items-center text-sm">
              <p className="font-semibold mr-3">Historik</p>
              <UIButtonDropdownGroup
                value={
                  (element.startDate != null ? element.startDate : 'Början') +
                  (element.endDate != null ? ` - ${element.endDate}` : ' (Nuvarande period)')
                }
                width="w-80"
                absolute="absolute"
                zIndex={'z-30'}
                translate={false}
                style="white"
              >
                {operatingCostsVersions.map((version, index) => (
                  <UIItemDropdown
                    value={version.startDate}
                    displayValue={
                      (version.startDate != null ? version.startDate : 'Början') +
                      (version.endDate != null ? ` - ${version.endDate}` : ' (Nuvarande period)')
                    }
                    onChange={() => onChangeVersion(version.startDate)}
                    key={index}
                  />
                ))}
              </UIButtonDropdownGroup>
            </div>
          )}
        </div>
      )}
      <UIAdvancedTable>
        {element?.operatingCosts?.length > 0 &&
          element.operatingCosts.map((operatingCostElement, idx) => (
            <UIAdvancedTableElement
              key={operatingCostElement.id}
              id={operatingCostElement.id}
              title={operatingCostElement.name}
              index={idx}
              canMove={false}
              onDelete={() => {
                setElement((prev) => ({
                  ...prev,
                  operatingCosts: prev.operatingCosts.filter((el) => el.id !== operatingCostElement.id),
                }));
              }}
            >
              <RegionInput
                onChange={(e) => {
                  setElement((prev) => ({
                    ...prev,
                    operatingCosts: prev.operatingCosts.map((el) =>
                      el.id === operatingCostElement.id ? { ...el, name: e.target.value } : el,
                    ),
                  }));
                }}
                value={operatingCostElement?.name}
                title="NAMN"
                isReadonly={isReadonly}
              />
              <RegionInput
                onChange={(e) => {
                  setElement((prev) => ({
                    ...prev,
                    operatingCosts: prev.operatingCosts.map((el) =>
                      el.id === operatingCostElement.id ? { ...el, cost: Number(e.target.value) } : el,
                    ),
                  }));
                }}
                value={operatingCostElement?.cost}
                title="KOSTNAD"
                type="number"
                isReadonly={isReadonly}
              />
              <UIInputWrapper
                title="KOSTNADSTYP"
                isLessLeftSpace={true}
              >
                <UIButtonDropdownGroup
                  value={operatingCostTypes.find((el) => el.id === operatingCostElement.costType.id)?.value}
                  isReadonly={isReadonly}
                  absolute="absolute"
                  zIndex="z-30"
                  translate={false}
                  style="white"
                >
                  {operatingCostTypes.map((operatingCostType, index) => (
                    <UIItemDropdown
                      value={operatingCostType.id}
                      displayValue={operatingCostType.value}
                      key={index}
                      onChange={() => {
                        setElement((prev) => ({
                          ...prev,
                          operatingCosts: prev.operatingCosts.map((el) =>
                            el.id === operatingCostElement.id ? { ...el, costType: operatingCostType } : el,
                          ),
                        }));
                      }}
                    />
                  ))}
                </UIButtonDropdownGroup>
              </UIInputWrapper>
              <UIInputWrapper
                title="FÖRDELNINGSNYCKEL"
                isLessLeftSpace={true}
              >
                <UIButtonDropdownGroup
                  value={
                    operatingCostDistributionKeys.find((el) => el.id === operatingCostElement.distributionKey.id)?.value
                  }
                  isReadonly={isReadonly}
                  absolute="absolute"
                  zIndex="z-30"
                  translate={false}
                  style="white"
                >
                  {operatingCostDistributionKeys.map((operatingCostDistributionKey, index) => (
                    <UIItemDropdown
                      value={operatingCostDistributionKey.id}
                      displayValue={operatingCostDistributionKey.value}
                      key={index}
                      onChange={() => {
                        setElement((prev) => ({
                          ...prev,
                          operatingCosts: prev.operatingCosts.map((el) =>
                            el.id === operatingCostElement.id
                              ? { ...el, distributionKey: operatingCostDistributionKey }
                              : el,
                          ),
                        }));
                      }}
                    />
                  ))}
                </UIButtonDropdownGroup>
              </UIInputWrapper>
            </UIAdvancedTableElement>
          ))}
      </UIAdvancedTable>
      <div className="pb-4 flex justify-center">
        <UIButtonSubmit
          name="Ny driftkostnad"
          assessments={true}
          isIcon={false}
          fixedWidth={false}
          onSubmit={() => setOpenWarningModal(true)}
        />
      </div>
      <UIWarningModal
        isOpen={openWarningModal}
        setOpen={setOpenWarningModal}
        onClick={handleCreateModalClick}
        modalTitle="Driftkostnad"
        modalMessage="Skapa ny driftkostnad?"
        confirmButtonName="Skapa"
      />
    </UIFoldableCard>
  );
};

RegionOperatingCostsVersionsForm.propTypes = {
  setElement: propTypes.func,
  element: propTypes.any,
  onEditVersion: propTypes.func,
  isReadonly: propTypes.bool,
  onOpenAddNewVersionModal: propTypes.func,
  isAdd: propTypes.bool,
  operatingCostsVersions: propTypes.array,
  onChangeVersion: propTypes.func,
};

export default RegionOperatingCostsVersionsForm;
