import React from 'react';
import PropTypes from 'prop-types';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import { activeGraphDefinition } from 'state/KubenDashboard/chartInformationModal';
import { currentUser } from 'state/global/currentUser';
import getGraphDefinitionIcon from 'utils/KubenDashboard/getGraphDefinitionIcon';
import formatNumber from 'utils/KubenDashboard/formatNumber';
import DashboardChartOptionsDropdown from './DashboardChartOptionsDropdown';
import DashboardPreviewChart from './DashboardPreviewChart';
import DashboardCreateGraphReport from './DashboardCreateGraphReport';
import UISpinner from 'components/global/UICommon/UISpinner';

const DashboardPreviewChartWrapper = ({
  chartId,
  graphDefinition,
  chosenGraphRegion,
  chosenGraphPeriod,
  chosenGraphFilters,
  isEditLayout,
  data,
}) => {
  const setActiveGraph = useSetRecoilState(activeGraphDefinition);
  const user = useRecoilValue(currentUser);
  const history = useHistory();

  const options = [
    {
      label: 'Gå till detaljvy',
      onClick: () => history.push('/dashboard/' + chartId),
      isVisible: history.location.pathname.endsWith('dashboard/') || history.location.pathname.endsWith('dashboard'),
    },
    {
      label: 'Visa information',
      onClick: () => setActiveGraph(graphDefinition),
      isVisible: true,
    },
    {
      isVisible: user.superAdministrator,
      component: (
        <DashboardCreateGraphReport
          key={chartId}
          chartId={chartId}
          regionId={chosenGraphRegion?.id}
          businessUnitIds={chosenGraphFilters.regionNodes}
          startDate={format(chosenGraphPeriod?.startDate, 'yyyy-MM-dd')}
          endDate={format(chosenGraphPeriod?.endDate, 'yyyy-MM-dd')}
        />
      ),
    },
  ];

  return (
    <div
      className={`flex flex-col background graph-wrapper rounded-xl px-3 pt-2 pb-2 w-full relative
           ${isEditLayout ? 'dragMe' : ''} h-full`}
    >
      {!data || !graphDefinition ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <UISpinner theme="fast" />
        </div>
      ) : (
        <>
          <div className="flex justify-between w-full">
            <div className="flex items-center h-fit space-x-2 w-full">
              <div className="graph-icon rounded p-1 h-[24px] w-[24px]">{getGraphDefinitionIcon(chartId)}</div>
              <div
                onClick={() => history.push('/dashboard/' + chartId)}
                className="preview-text text-sm font-medium flex select-text hover:cursor-pointer"
              >
                {graphDefinition.title}
              </div>
              {!isEditLayout && (
                <IoMdInformationCircleOutline
                  className="text-xl opacity-70 hover:opacity-100 title
                    hover:scale-105 transition-all duration-150 ease-in-out"
                  onClick={() => setActiveGraph(graphDefinition)}
                />
              )}
            </div>
            {!isEditLayout && (
              <DashboardChartOptionsDropdown iconSize="text-base">
                {options
                  .filter((option) => option.isVisible)
                  .map(
                    (option, idx) =>
                      option.component || (
                        <div
                          key={idx}
                          className="w-full pl-4 h-full flex items-center"
                          onClick={option.onClick}
                        >
                          {option.label}
                        </div>
                      ),
                  )}
              </DashboardChartOptionsDropdown>
            )}
          </div>
          <div className="flex h-full justify-between space-x-8">
            <div className="w-1/3 flex items-center">
              <div
                className="whitespace-nowrap select-text text-left preview-text
                 text-2xl 3xl:text-3xl font-medium"
              >
                {formatNumber(data.highlightedValue)}
                <span className="text-base 3xl:text-lg font-medium">
                  {' '}
                  {data.titles?.length > 0 ? data.titles[0]?.units : ''}
                </span>
              </div>
            </div>
            <DashboardPreviewChart chartData={data} />
          </div>
        </>
      )}
    </div>
  );
};

DashboardPreviewChartWrapper.propTypes = {
  chartId: PropTypes.string.isRequired,
  graphDefinition: PropTypes.any,
  chosenGraphFilters: PropTypes.any,
  chosenGraphRegion: PropTypes.object.isRequired,
  chosenGraphPeriod: PropTypes.object.isRequired,
  isEditLayout: PropTypes.bool,
  data: PropTypes.object,
};

export default DashboardPreviewChartWrapper;
