import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import useFetchBusinessTypes from 'hooks/api/businessType/useFetchBusinessTypes';
import useFetchResourceGroups from 'hooks/api/businessType/useFetchResourceGroups';

import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UISpinner from 'components/global/UICommon/UISpinner';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIListModal from 'components/global/UIModals/UIListModal';

import UIInputWrapper from '../UIAdmin/UIInputWrapper';
import TemplateInput from './TemplateInput';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';

const TemplateCalculationResultPropertiesForm = ({ index }) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useRecoilState(templateFormState);
  const calculationResult = template.calculationResults[index];

  const [isAddStairCase, setAddStaircase] = useState(false);
  const [isAddLevel, setAddLevel] = useState(false);

  const {
    data: businessTypes,
    isLoading: isBusinessTypesLoading,
    isError: isBusinessTypesError,
  } = useFetchBusinessTypes();
  const {
    data: resourceGroups,
    isLoading: isResourceGroupsLoading,
    isError: isResourceGroupsError,
  } = useFetchResourceGroups();

  const businessType = businessTypes.find((businessType) => businessType.id === calculationResult.businessTypeId);

  const filteredResourceGroups = resourceGroups?.filter((resourceGroup) =>
    businessType?.resourceGroupIds.includes(resourceGroup.id),
  );

  const filteredStaircases = businessType.referenceDataVersions[
    businessType.referenceDataVersions.length - 1
  ]?.staffings?.filter(
    (newStaircases) =>
      !calculationResult.staffingByUnitTypeAndLevelReferences?.some(
        (currentStaircases) => currentStaircases.staffingsKey === newStaircases.key,
      ),
  );

  const filteredLevels = businessType.referenceDataVersions[
    businessType.referenceDataVersions.length - 1
  ]?.levels?.filter(
    (newLevels) => !calculationResult.levelsOverwrite?.some((currentLevels) => currentLevels.level === newLevels.level),
  );

  const onChange = (name, value, levelIndex = null, staircaseIndex = null, isDelete = false) => {
    const tmpTemplate = { ...template };
    const tmpCalculationResults = [...tmpTemplate.calculationResults];
    const tmpCalculationResult = { ...tmpCalculationResults[index] };

    if (levelIndex !== null) {
      if (isDelete) {
        tmpCalculationResult.levelsOverwrite = tmpCalculationResult.levelsOverwrite.filter(
          (item, idx) => idx !== levelIndex,
        );
        if (tmpCalculationResult.levelsOverwrite.length === 0) {
          tmpCalculationResult.levelsOverwrite = null;
        }
      } else {
        const tmpLevelsOverwrite = [...tmpCalculationResult.levelsOverwrite];
        tmpLevelsOverwrite[levelIndex] = {
          ...tmpLevelsOverwrite[levelIndex],
          [name]: value,
        };
        tmpCalculationResult.levelsOverwrite = tmpLevelsOverwrite;
      }
    } else if (staircaseIndex !== null) {
      if (isDelete) {
        tmpCalculationResult.staffingByUnitTypeAndLevelReferences =
          tmpCalculationResult.staffingByUnitTypeAndLevelReferences.filter((item, idx) => idx !== staircaseIndex);
        if (tmpCalculationResult.staffingByUnitTypeAndLevelReferences.length === 0) {
          tmpCalculationResult.staffingByUnitTypeAndLevelReferences = null;
        }
      } else {
        const tmpStaffingByUnitTypeAndLevelReferences = [...tmpCalculationResult.staffingByUnitTypeAndLevelReferences];
        tmpStaffingByUnitTypeAndLevelReferences[staircaseIndex] = {
          ...tmpStaffingByUnitTypeAndLevelReferences[staircaseIndex],
          [name]: value,
        };
        tmpCalculationResult.staffingByUnitTypeAndLevelReferences = tmpStaffingByUnitTypeAndLevelReferences;
      }
    } else {
      tmpCalculationResult[name] = value;
    }

    tmpCalculationResults[index] = tmpCalculationResult;
    tmpTemplate.calculationResults = tmpCalculationResults;

    setTemplate(tmpTemplate);
  };

  const onAddLevel = (element) => {
    const tmpTemplate = { ...template };
    const tmpCalculationResults = [...tmpTemplate.calculationResults];
    const tmpCalculationResult = { ...tmpCalculationResults[index] };

    const newLevel = {
      name: null,
      level: element.level,
      minPoints: element.minPoints,
      maxPoints: element.maxPoints,
    };

    tmpCalculationResult.levelsOverwrite = [...(tmpCalculationResult.levelsOverwrite || []), newLevel];

    tmpCalculationResults[index] = tmpCalculationResult;
    tmpTemplate.calculationResults = tmpCalculationResults;

    setTemplate(tmpTemplate);
  };

  const onAddStaircase = (element) => {
    const tmpTemplate = { ...template };
    const tmpCalculationResults = [...tmpTemplate.calculationResults];
    const tmpCalculationResult = { ...tmpCalculationResults[index] };

    const newStaircase = {
      staffingsKey: element,
      formula: null,
    };

    tmpCalculationResult.staffingByUnitTypeAndLevelReferences = [
      ...(tmpCalculationResult.staffingByUnitTypeAndLevelReferences || []),
      newStaircase,
    ];

    tmpCalculationResults[index] = tmpCalculationResult;
    tmpTemplate.calculationResults = tmpCalculationResults;

    setTemplate(tmpTemplate);
  };

  if (isBusinessTypesLoading || isResourceGroupsLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isBusinessTypesError || isResourceGroupsError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <div>
      <TemplateInput
        title="Id"
        disabled={true}
        value={calculationResult.id}
      />
      <TemplateInput
        title="Namn"
        value={calculationResult.name}
        onChange={(e) => onChange('name', e.target.value)}
      />
      <TemplateInput
        title="Summeringstext"
        value={calculationResult.summaryText}
        onChange={(e) => onChange('summaryText', e.target.value)}
      />
      <UIInputWrapper title="Bara synlig för admin">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('adminOnly', checked)}
          checked={calculationResult.adminOnly}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Resultat är nivåbaserat">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('staffingIsLevelBased', checked)}
          checked={calculationResult.staffingIsLevelBased}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Genererar resursbehov">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('generateResourceNeeds', checked)}
          checked={calculationResult.generateResourceNeeds}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Insatstyp">
        <UIButtonDropdownGroup
          translate={false}
          isReadonly={true}
          value={businessTypes.find((el) => el.id === calculationResult.businessTypeId)?.name}
          absolute="absolute"
          zIndex="z-20"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Resurstyp">
        <UIButtonDropdownGroup
          translate={false}
          value={filteredResourceGroups.find((el) => el.id === calculationResult.resourceGroupId)?.value}
          absolute="absolute"
          zIndex="z-20"
        >
          {filteredResourceGroups.map((sel, idx) => (
            <UIItemDropdown
              key={idx}
              value={sel.value}
              onChange={() => onChange('resourceGroupId', sel.id)}
              displayValue={sel.value}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Beräkningsgrupp">
        <UIButtonDropdownGroup
          translate={false}
          value={template.calculationGroups.find((el) => el.id === calculationResult.pointSource)?.name}
        >
          <UIItemDropdown
            value={null}
            displayValue="Välj"
            onChange={() => onChange('pointSource', null)}
          />
          {template.calculationGroups.map((sel, idx) => {
            return (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.name}
                key={idx}
                onChange={() => onChange('pointSource', sel.id)}
              />
            );
          })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <TemplateInput
        title="Formula"
        value={calculationResult.formula}
        onChange={(e) => onChange('formula', e.target.value)}
      />
      <div className="font-semibold md:text-lg text-xl uppercase text p-2">Trappor</div>
      {calculationResult.staffingByUnitTypeAndLevelReferences?.map((el, idx) => (
        <UIAdvancedTableElement
          key={`key_${el.staffingsKey}`}
          id={`id_${el.staffingsKey}`}
          index={idx}
          title={el.staffingsKey}
          canMove={false}
          onDelete={() => onChange(null, null, null, idx, true)}
        >
          <TemplateInput
            title="Trappa"
            value={el.staffingsKey}
            disabled={true}
          />
          <TemplateInput
            title="Formula"
            value={el.formula}
            disabled={!calculationResult.staffingIsLevelBased}
            onChange={(e) => onChange('formula', e.target.value, null, idx)}
          />
        </UIAdvancedTableElement>
      ))}
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Lägg till trappa"
          onSubmit={() => setAddStaircase(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      <div className="font-semibold md:text-lg text-xl uppercase text p-2">Nivåer</div>
      {calculationResult.levelsOverwrite?.map((el, idx) => {
        const businessTypeLevel = businessType.referenceDataVersions[
          businessType.referenceDataVersions.length - 1
        ]?.levels?.find((level) => level.level === el.level);
        return (
          <UIAdvancedTableElement
            key={`key_${el.level}`}
            id={`id_${el.level}`}
            index={idx}
            title={el.name ? el.name : businessTypeLevel?.name}
            canMove={false}
            onDelete={() => onChange(null, null, idx, null, true)}
          >
            <TemplateInput
              title="namn"
              value={el.name}
              disabled={!calculationResult.staffingIsLevelBased}
              onChange={(e) => onChange('name', e.target.value === '' ? null : e.target.value, idx)}
            />
            <TemplateInput
              title="level"
              value={el.level}
              type="number"
              disabled={true}
              onChange={(e) => onChange('level', Number(e.target.value), idx)}
            />
            <TemplateInput
              title="min poäng"
              value={el.minPoints}
              type="number"
              disabled={!calculationResult.staffingIsLevelBased}
              onChange={(e) => onChange('minPoints', Number(e.target.value), idx)}
            />
            <TemplateInput
              title="max poäng"
              value={el.maxPoints}
              type="number"
              disabled={!calculationResult.staffingIsLevelBased}
              onChange={(e) => onChange('maxPoints', Number(e.target.value), idx)}
            />
          </UIAdvancedTableElement>
        );
      })}
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Lägg till nivå"
          onSubmit={() => setAddLevel(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      {isAddStairCase && (
        <UIListModal
          modalTitle="Trappa"
          isOpen={true}
          data={filteredStaircases?.map((filteredStaircase) => ({
            id: filteredStaircase.id,
            value: filteredStaircase.key,
          }))}
          onClose={() => setAddStaircase(false)}
          handleClick={(element) => onAddStaircase(element.value)}
          isSearchBar={false}
        />
      )}
      {isAddLevel && (
        <UIListModal
          modalTitle="Nivå"
          isOpen={true}
          data={filteredLevels?.map((filteredLevel) => ({
            level: filteredLevel.level,
            name: filteredLevel.name,
            minPoints: filteredLevel.minPoints,
            maxPoints: filteredLevel.maxPoints,
          }))}
          onClose={() => setAddLevel(false)}
          handleClick={(element) => onAddLevel(element)}
          isSearchBar={false}
        />
      )}
    </div>
  );
};

TemplateCalculationResultPropertiesForm.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TemplateCalculationResultPropertiesForm;
